<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="displayedItems"
    :mobile-breakpoint="0"
    :loading="loading"
    :class="{ 'content-loading': loading }"
    class="overflow-auto"
    scrollable
    disable-pagination
    hide-default-footer
    disable-sort
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.file_name="{ item }">
      <BaseTruncatedField :text="item.file_name" :truncatedLength="21" />
    </template>

    <template v-slot:item.file_actions="{ item }">
      <div>
        <v-btn
          outlined
          x-small
          class="blue-grey--text mr-2"
          @click="downloadFile(item.file_url, item.file_name)"
        >
          {{ $t('download') }}
        </v-btn>
        <v-btn
          v-if="$canPreviewFile(getExtension(item))"
          outlined
          x-small
          class="blue-grey--text"
          @click="$emit('view:document', item)"
        >
          {{ $t('view') }}
        </v-btn>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :item="item"
        :loading="$store.getters.loading[`delete:api/order-documents/${item.id}`]"
      />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.file_actions>
          <div>
            <v-btn
              outlined
              x-small
              class="blue-grey--text mr-2"
              @click="downloadFile(item.file_url, item.file_name)"
            >
              {{ $t('download') }}
            </v-btn>
            <v-btn
              v-if="$canPreviewFile(getExtension(item))"
              outlined
              x-small
              class="blue-grey--text"
              @click="$emit('view:document', item)"
            >
              {{ $t('view') }}
            </v-btn>
          </div>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import BaseTruncatedField from '@/components/base/BaseTruncatedField';
import { downloadFileFromAPI } from '@/util/files';

export default {
  name: 'DocumentTable',

  components: { BaseTruncatedField, BaseExpandedTableRow, BaseActionMenu },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('file_name'),
          value: 'file_name',
        },
        {
          value: 'file_actions',
          align: 'end',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    displayedItems() {
      if (!this.items) {
        return [];
      }
      return this.items.map((r) => ({
        ...r,
      }));
    },
  },

  methods: {
    getExtension(item) {
      return item.file_name.split('.').pop();
    },

    getRowActions() {
      const actions = [
        {
          callback: (p) => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];
      return actions;
    },

    downloadFile(url, name) {
      downloadFileFromAPI(url, name);
    },
  },
};
</script>

<style scoped></style>
